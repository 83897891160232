import StudentSelectionView from "views/StudentSelection";
import {
  parseStrings,
  studentDataDeDecoration,
} from "utils/studentDataProcessing";
import SchoolService from "services/School";
import AuthService from "services/Auth";
import { redirect } from "react-router-dom";

export default {
  element: <StudentSelectionView />,
  path: "student-selection",
  action: async ({ params, request }) => {
    const SPHINX_GATE_API_URL = import.meta.env.VITE_SPHINX_GATE_API_URL;
    const { schoolId } = params;

    await AuthService.auth();
    if (AuthService.isAuthenticated) {
      SchoolService.api.token = AuthService.api.token;
      const formData = await request.formData();

      // ****************************
      // Validations
      // ****************************
      const numOpenSeats = formData.get("num-open-seats");
      if (numOpenSeats < 0) {
        return [
          {
            message:
              "Oops! You've selected more students than available seats.",
          },
        ];
      }

      // ****************************
      // Submit to spark
      // ****************************
      const formObject = Object.fromEntries(formData);
      const deselectedStudents = formData
        .get("deselected-students")
        ?.split(",");

      let students = await studentDataDeDecoration(formObject, []);
      if (deselectedStudents && deselectedStudents != "") {
        deselectedStudents.forEach(fieldName => {
          students.push({
            uuid: fieldName.split("_")[1],
            student_program_year_uuid: fieldName.split("_")[2],
            selected: false,
          });
        });
      }

      if (students.length > 0) {
        const updateData = { students };

        const rosterStatus = parseStrings(
          "roster-status",
          formData.get("roster-status")
        );
        if (rosterStatus !== "") {
          updateData.student_roster_status = rosterStatus;
        }

        await SchoolService.updateStudents(schoolId, updateData);
      }

      return redirect(`/schools/${schoolId}/student-info-completion`);
    }
    return redirect(`${SPHINX_GATE_API_URL}/login`);
  },
};
