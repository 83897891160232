import axios from "axios";
import { cleanUrl, parseErrorMessage } from "utils/api";

const DEFAULT_CONFIG = {
  baseUrl: "/",
  namespace: "/",
  withCredentials: true,
  preflightHeaders: undefined,
  defaultHeaders: {},
};

export default class Api {
  get baseURL() {
    // Clean the url from extra slashes
    return cleanUrl(`${this.config.baseUrl}/${this.config.namespace}`);
  }

  constructor(opts = {}) {
    // Configuration settings for the service instance
    this.config = Object.assign(DEFAULT_CONFIG, opts);
    // Make axios send cookies withCredentials=true
    this.ajax = axios.create({
      baseURL: this.baseURL,
      withCredentials: this.config.withCredentials,
    });
    // Set content type to json
    this.ajax.defaults.headers.common["Content-Type"] = "application/json";
    // Store the token
    this._token = null;

    try {
      // Custom Default Headers
      for (let header in this.config.defaultHeaders) {
        this.ajax.defaults.headers.common[header] =
          this.config.defaultHeaders[header];
      }
    } catch (_) {
      return false;
    }
  }

  get token() {
    return this._token;
  }

  set token(token) {
    if (token) {
      this.ajax.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete this.ajax.defaults.headers.common["Authorization"];
    }
    this._token = token;
  }

  get headers() {
    return this.ajax.defaults.headers.common;
  }

  set headers(headers) {
    for (let header in headers) {
      this.ajax.defaults.headers.common[header] = headers[header];
    }
  }

  async fetch(
    { body, headers = {}, method = "get", params = {}, signal, url },
    preflightOptions
  ) {
    const response = {
      data: null,
      error: false,
      errorMessage: undefined,
      status: undefined,
      success: false,
    };

    let preflightHeaders = {};

    if (typeof this.config.preflightHeaders === "function") {
      preflightHeaders = this.config.preflightHeaders(preflightOptions);
    }

    headers = Object.assign(headers, preflightHeaders);

    try {
      const res = await this.ajax({
        data: body,
        headers,
        method,
        params,
        signal,
        url,
      });

      response.data = res.data;
      response.status = res.status;
      response.success = true;

      return response;
    } catch (error) {
      // Ignore errors from deliberate signal canceling
      if (error.name !== "CanceledError") {
        response.data =
          (typeof error?.response?.data !== "string" &&
            error?.response?.data) ||
          {};
        response.error = true;
        response.errorMessage = parseErrorMessage(error);
        response.status = error?.response?.status || error.code;
      }

      return response;
    }
  }
}
