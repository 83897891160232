import PropTypes from "prop-types";
import DownArrowIcon from "assets/icons/arrow_down.svg";
import classnames from "classnames";
import { useEffect, useState } from "react";
import WithWrapper from "../WithWrapper";

/**
 * Styled select for the DataGrid.
 *
 * @param {boolean} [error = false] - boolean to indicate error state
 * @param {boolean} [disabled = false] - boolean to indicate disabled state
 * @param {string} value - value to display in the select
 * @param {string} name - name of the select
 * @param {string} id - id of the select
 * @param {string} className - additional classes to add to the component
 * @param {array} options - array of objects with label and value for the options
 * @param {string} columnHeader - column header for the field
 * @param {boolean} shouldStartPink - boolean to indicate if the field should start pink
 * @param {func} onBlur - function to run on blur event
 */
const DataGridSelect = ({
  error = false,
  disabled = false,
  value = "",
  name = "",
  id = "",
  className,
  options,
  columnHeader,
  shouldStartPink,
  onBlur,
  ...args
}) => {
  const [inputValue, setInputValue] = useState(value?.toLowerCase());

  const handleInputValueChange = event => {
    setInputValue(event.target.value);
  };

  // Needed for sorting
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <WithWrapper
      shouldStartPink={shouldStartPink}
      value={inputValue}>
      <div
        className={classnames(
          "w-full h-full flex rounded items-center justify-center focus-within:ring-2 focus-within:ring-ignite-pink",
          className
        )}>
        <div className="relative">
          <select
            className={classnames(
              "peer h-14 text-center [background-position-x:100%] truncate appearance-none bg-transparent focus-visible:outline-none pr-10 pl-2",
              {
                "text-error-red": error,
                "cursor-pointer": !disabled,
              }
            )}
            value={inputValue || ""}
            onChange={handleInputValueChange}
            onBlur={onBlur}
            name={name}
            data-testid={name}
            id={id}
            aria-label={columnHeader}
            aria-invalid={error}
            disabled={disabled}
            {...args}>
            <option value="">Select</option>
            {options.map(({ label, value }) => (
              <option
                key={value}
                value={value}>
                {label}
              </option>
            ))}
          </select>
          <DownArrowIcon
            className={classnames(
              "absolute right-0 top-[calc(50%-12px)] pointer-events-none rounded-full box-content mr-2",
              {
                "cursor-pointer peer-focus-visible:bg-ignite-pink peer-hover:bg-ignite-pink peer-focus-visible:fill-white peer-hover:fill-white":
                  !disabled,
              }
            )}
          />
        </div>
      </div>
    </WithWrapper>
  );
};

DataGridSelect.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]).isRequired,
    })
  ).isRequired,
  columnHeader: PropTypes.string.isRequired,
  shouldStartPink: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
};

export default DataGridSelect;
