import { DATA_TYPE } from "constants/metadata/data_type";
import DataGridSelect from "./DataGridSelect";
import DataGridDateInput from "./DataGridDateInput";
import DataGridTextInput from "./DataGridTextInput";
import PropTypes from "prop-types";
import DataGridMultiSelect from "./DataGridMultiSelect";

function DataGridInput({
  dataType,
  options,
  id,
  name,
  value, // for single inputs
  values, // for multi-select inputs
  colHeader,
  error = false,
  disabled = false,
  shouldStartPink,
  onBlur,
}) {
  switch (dataType) {
    case DATA_TYPE.MULTISELECT:
      return (
        <DataGridMultiSelect
          label="Select"
          columnHeader={colHeader}
          error={error}
          options={options}
          name={name}
          id={id}
          values={values}
          disabled={disabled}
          shouldStartPink={shouldStartPink}
          onBlur={onBlur}
        />
      );
    case DATA_TYPE.SELECT:
      return (
        <DataGridSelect
          value={value}
          options={options}
          columnHeader={colHeader}
          error={error}
          disabled={disabled}
          name={name}
          id={id}
          shouldStartPink={shouldStartPink}
          onBlur={onBlur}
        />
      );
    case DATA_TYPE.DATE:
      return (
        <DataGridDateInput
          error={error}
          value={value}
          columnHeader={colHeader}
          disabled={disabled}
          name={name}
          id={id}
          shouldStartPink={shouldStartPink}
          onBlur={onBlur}
        />
      );
    default:
      return (
        <DataGridTextInput
          error={error}
          value={value}
          placeholder={colHeader}
          columnHeader={colHeader}
          disabled={disabled}
          name={name}
          id={id}
          shouldStartPink={shouldStartPink}
          onBlur={onBlur}
        />
      );
  }
}

DataGridInput.propTypes = {
  dataType: PropTypes.oneOf([
    DATA_TYPE.MULTISELECT,
    DATA_TYPE.SELECT,
    DATA_TYPE.STRING,
    DATA_TYPE.DATE,
  ]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.array,
  colHeader: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  shouldStartPink: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
};

export default DataGridInput;
