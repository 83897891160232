import {
  FloatingArrow,
  FloatingPortal,
  useMergeRefs,
} from "@floating-ui/react";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef, useContext, useEffect } from "react";
import TooltipContext from "./context";
import twcx from "utils/twcx";
import {
  placementToExitXMap,
  placementToExitYMap,
  placementToOriginXMap,
  placementToOriginYMap,
} from "constants/dictionaries/placement";

const ANIMATE_IN_DURATION_IN_MS = 60;
const ANIMATE_OUT_DURATION_IN_MS = 80;

const TooltipContent = forwardRef(
  ({ children, className = "", dark = false, ...props }, propRef) => {
    const {
      arrowRef,
      context: floatingContext,
      onAfterOpenChange,
      placement,
      ...context
    } = useContext(TooltipContext);
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const shouldReduceMotion = useReducedMotion();

    useEffect(() => {
      onAfterOpenChange(floatingContext.open);
    }, [floatingContext.open, onAfterOpenChange]);

    return (
      <AnimatePresence>
        {floatingContext.open ? (
          <FloatingPortal>
            <div
              ref={ref}
              style={{
                zIndex: 10,
                ...context.floatingStyles,
                ...props.style,
              }}
              {...context.getFloatingProps(props)}>
              <motion.div
                className={twcx(
                  "rounded-lg text-sm ring-1",
                  "px-3.5 py-2.5 z-[900] max-w-[400px] min-w-min text-center",
                  dark
                    ? "bg-black border-black ring-black text-white"
                    : "bg-white shadow-xl ring-zinc-200 text-zinc-600",
                  className
                )}
                initial={{ opacity: 0, scale: 1 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: { duration: ANIMATE_IN_DURATION_IN_MS / 1000 },
                  y: 0,
                }}
                exit={{
                  opacity: 0,
                  scale: shouldReduceMotion ? 1 : 0.8,
                  transition: { duration: ANIMATE_OUT_DURATION_IN_MS / 1000 },
                  x: shouldReduceMotion ? 0 : placementToExitXMap[placement],
                  y: shouldReduceMotion ? 0 : placementToExitYMap[placement],
                }}
                style={{
                  originX: placementToOriginXMap[placement],
                  originY: placementToOriginYMap[placement],
                }}>
                {children}
                <FloatingArrow
                  className={dark ? "stroke-black" : "stroke-zinc-200"}
                  context={floatingContext}
                  fill={dark ? "black" : "white"}
                  ref={arrowRef}
                  strokeWidth={1}
                />
              </motion.div>
            </div>
          </FloatingPortal>
        ) : null}
      </AnimatePresence>
    );
  }
);

TooltipContent.displayName = "Tooltip.Content";
TooltipContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dark: PropTypes.bool,
  style: PropTypes.object,
};

export default TooltipContent;
