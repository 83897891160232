import PropTypes from "prop-types";

/**
 * Simple layout for landing pages that generally look the same. Used on each view.
 *
 * @param {string || node} [welcomeMessage = "welcome back"] - welcome message to display
 * @param {string} title - title of the landing page
 * @param {string} bodyText - body text of the landing page
 * @param {node} callToAction - call to action buttons
 */
export default function LandingLayout({
  welcomeMessage = "Welcome back",
  title,
  bodyText,
  callToAction,
}) {
  return (
    <div className="px-5 content-center text-center justify-items-center navbar-height-offset">
      <h1 className="text-ignite-purple-dark text-2xl">{welcomeMessage}</h1>
      <h2 className="text-ignite-purple-dark text-[2.5rem] font-bold mt-4 leading-[48px]">
        {title}
      </h2>
      <p className="text-ignite-grey-dark text-base mt-5 ">{bodyText}</p>
      <div className="mt-10">{callToAction}</div>
    </div>
  );
}

LandingLayout.propTypes = {
  welcomeMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  callToAction: PropTypes.node.isRequired,
};
