import { validatePassword } from "utils/validation";
import AuthService from "services/Auth";
import SchoolService from "services/School";
import SignUpView from "views/SignUp";
import { redirect } from "react-router-dom";

export default {
  element: <SignUpView />,
  path: "/signup",
  loader: async ({ request }) => {
    const token = new URL(request.url).searchParams.get("token");

    const { data: tokenInfo, status } = await SchoolService.tokenInfo(token);

    if (status === 410) {
      return redirect("/link-not-active");
    }

    if (status === 404) {
      throw { status, message: "Error fetching token information" };
    }

    const schoolName = tokenInfo.school_name
      ? tokenInfo.school_name
      : tokenInfo.school_system_name;
    return {
      email: tokenInfo.email,
      schoolDistrictName: tokenInfo.school_system_name,
      schoolName: schoolName,
      token: token,
    };
  },
  action: async ({ request }) => {
    const formData = await request.formData();

    const { confirm, firstName, lastName, password, token } =
      Object.fromEntries(formData);

    const errors = {};

    errors.password = !validatePassword(password);
    errors.confirm = !(password === confirm);

    if (errors.password || errors.confirm) {
      return errors;
    }

    const resp = await AuthService.signup(firstName, lastName, password, token);
    if (resp) {
      await SchoolService.signout();
      return await AuthService.signout();
    }
    return false;
  },
};
