export function compareInsensitive(a, b) {
  const stringA = String(a).toLowerCase();
  const stringB = String(b).toLowerCase();

  return stringA === stringB;
}

export function includesInsensitive(a, b) {
  const stringA = String(a).toLowerCase();
  const stringB = String(b).toLowerCase();

  return stringA.includes(stringB);
}
