import { SORT_DIRECTION, SORT_TYPE } from "constants/enums/sort";
import PropTypes from "prop-types";
import DownArrowIcon from "assets/icons/arrow_down.svg";
import UpArrowIcon from "assets/icons/arrow_up.svg";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

function SortIcon({ activeSortDirection, className }) {
  return activeSortDirection === SORT_DIRECTION.ASCENDING ? (
    <UpArrowIcon
      className={className}
      data-testid="up-arrow"
    />
  ) : activeSortDirection === SORT_DIRECTION.DESCENDING ? (
    <DownArrowIcon
      className={className}
      data-testid="down-arrow"
    />
  ) : (
    <UpArrowIcon
      className={className}
      data-testid="up-arrow"
    />
  );
}

SortIcon.propTypes = {
  activeSortDirection: PropTypes.oneOf([
    SORT_DIRECTION.ASCENDING,
    SORT_DIRECTION.DESCENDING,
    SORT_DIRECTION.NONE,
  ]).isRequired,
  className: PropTypes.string,
};

function SortHeader({
  children,
  toggleSort,
  activeSortDirection,
  field,
  sortIndex,
  sortType,
  className,
}) {
  const nextSortDirection = activeSortDirection => {
    switch (activeSortDirection) {
      case SORT_DIRECTION.NONE:
        return SORT_DIRECTION.ASCENDING;
      case SORT_DIRECTION.ASCENDING:
        return SORT_DIRECTION.DESCENDING;
      case SORT_DIRECTION.DESCENDING:
        return SORT_DIRECTION.NONE;
      default:
        return SORT_DIRECTION.NONE;
    }
  };

  return (
    <button
      onClick={() => toggleSort(sortIndex, activeSortDirection, sortType)}
      data-testid={`sort-${field.replace(/\s+/g, "-")}`}
      aria-label={`Sort by ${field}, ${nextSortDirection(activeSortDirection)}`}
      type="button"
      className={twMerge(
        "group h-14 flex items-center w-full focus-visible:outline-none",
        className
      )}>
      {children}
      <span
        className={classNames(
          "mx-2 rounded-full group-focus-visible:bg-ignite-pink group-hover:bg-ignite-pink",
          {
            "opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100":
              activeSortDirection === SORT_DIRECTION.NONE,
          }
        )}>
        <SortIcon
          activeSortDirection={activeSortDirection}
          className="group-focus-visible:fill-white group-hover:fill-white"
        />
      </span>
    </button>
  );
}

SortHeader.propTypes = {
  children: PropTypes.node.isRequired,
  toggleSort: PropTypes.func.isRequired,
  activeSortDirection: PropTypes.oneOf([
    SORT_DIRECTION.ASCENDING,
    SORT_DIRECTION.DESCENDING,
    SORT_DIRECTION.NONE,
  ]).isRequired,
  field: PropTypes.string.isRequired,
  sortIndex: PropTypes.number.isRequired,
  sortType: PropTypes.oneOf([
    SORT_TYPE.ALPHANUMERIC,
    SORT_TYPE.DATE,
    SORT_TYPE.GRADE,
    SORT_TYPE.DISABLED,
  ]).isRequired,
  className: PropTypes.string,
};

export default SortHeader;
