import { InformationStep } from "components";
import PropTypes from "prop-types";
import classnames from "classnames";

/**
 * Stylized progress display for the flow of completing student information
 * @param className - any additional classes to add to the component, for ex margins
 * @param steps - array of objects representing the steps in the flow of completing student information.
 * Each object should have the following properties (See InformationStep for more details):
 * - text
 * - stepStatus
 */
export default function InformationProgress({ className, steps }) {
  return (
    <div
      className={classnames(
        "border-ignite-grey-light-alt border rounded p-4",
        className
      )}>
      <h2 className="text-ignite-purple text-xl font-bold mb-10">
        Complete student information
      </h2>
      {steps.map((step, index) => (
        <InformationStep
          key={index}
          className="mt-5"
          text={step.text}
          stepStatus={step.stepStatus}
        />
      ))}
    </div>
  );
}

InformationProgress.propTypes = {
  className: PropTypes.string, // accept className for placement
  steps: PropTypes.array.isRequired,
};
