import { SchoolCard } from "components";
import { useRouteLoaderData } from "react-router-dom";

export default function SchoolSelectionView() {
  const { schools } = useRouteLoaderData("auth");

  return (
    <div className="content-center mx-[5%] xl:mx-40 navbar-height-offset">
      <div>
        <h1 className="mt-9 text-[42px] text-ignite-purple-dark font-bold">
          Select a school to get started.
        </h1>
        <h2 className="my-3.5 text-2xl">Set up your Ignite Reading program.</h2>
        <ul className="mt-12">
          {schools.map((school, i) => (
            <SchoolCard
              key={school.uuid}
              school={school}
              lastCard={i === schools.length - 1}
              testID={`${school.name}-card`}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
