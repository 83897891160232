import PropTypes from "prop-types";
import classnames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { getPageEditing } from "utils/nav";
import { ROSTER_NAV_ITEMS } from "constants/dictionaries/nav";

/**
 * Stylized navigation item for the header
 * @param className - any additional classes to add to the component, for ex margins
 * @param location: location that the user is routed to on click
 * @param text: text to display in the anchor navigation item
 * @param active: boolean to determine if the item is active
 * @param lastItem: boolean to determine if the item is the last in the list
 * @param available: boolean to determine if the item is available for navigation
 */

export default function HeaderNavigationItem({ className, lastItem, item }) {
  const navigate = useNavigate();
  const location = useLocation();

  let classes;
  if (item.active) {
    classes = "before:bg-ignite-purple before:text-white before:drop-shadow";
  } else if (item.available) {
    classes = "before:border-[1px] before:border-ignite-grey";
  } else {
    classes = "before:bg-ignite-grey-light before:text-ignite-grey-dark-alt";
  }

  const Number = () => (
    <span
      className={classnames(
        classes,
        // Use the counter as the number
        "before:[counter-increment:list-number] before:content-[counter(list-number)]",
        // Style the number
        "before:inline-flex before:justify-center before:items-center before:w-6 before:h-6 before:rounded-full before:mr-2 before:text-xs"
      )}
    />
  );

  const handleNavigation = () => {
    const editCheck = getPageEditing(location.pathname, ROSTER_NAV_ITEMS);

    if (editCheck) {
      if (
        confirm(
          "Have you made changes? Click cancel to stay on this page and save your work."
        )
      ) {
        navigate(item.location);
      }
    } else {
      navigate(item.location);
    }
  };

  return (
    <li
      className={classnames(className, "flex group text-black text-nowrap", {
        "after:content-[''] after:block after:w-10 after:h-px after:bg-ignite-grey-light after:mx-4 after:mt-3":
          !lastItem,
        "text-ignite-grey-alt": !item.active && !item.available,
      })}
      aria-current={item.active}>
      {item.available && !item.active ? (
        <button
          className="group-hover:underline focus-visible:underline"
          onClick={handleNavigation}>
          <Number />
          {item.text}
        </button>
      ) : (
        <>
          <Number />
          {item.text}
        </>
      )}
    </li>
  );
}

HeaderNavigationItem.propTypes = {
  className: PropTypes.string, // accept className for placement
  lastItem: PropTypes.bool,
  item: PropTypes.shape({
    location: PropTypes.string,
    text: PropTypes.string,
    active: PropTypes.bool,
    available: PropTypes.bool,
    dataEditing: PropTypes.bool,
  }).isRequired,
};
