import { ROSTER_STATUS, SCHOOL_STATUS } from "constants/enums/school_status";

//statuses in which a school is available in the flow
const availableSchoolStatuses = [
  SCHOOL_STATUS.IMPORTED_RECORDS,
  SCHOOL_STATUS.OPEN_SEATS,
  SCHOOL_STATUS.SI_INCOMPLETE,
  SCHOOL_STATUS.READY,
];

export const ROSTER_NAV_ITEMS = {
  0: {
    page: "school-selection",
    text: "Select School",
    needsSchoolId: false,
    dataEditing: false,
    //let's use an empty array to stand in for "all"
    availableStatuses: { schoolStatus: [], student_roster_status: [] },
  },
  1: {
    page: "student-selection",
    text: "Select Students",
    needsSchoolId: true,
    dataEditing: true,
    availableStatuses: {
      schoolStatus: [...availableSchoolStatuses],
      student_roster_status: [],
    },
  },
  2: {
    page: "student-info-completion",
    text: "Complete Student Information",
    needsSchoolId: true,
    dataEditing: true,
    availableStatuses: {
      schoolStatus: [...availableSchoolStatuses],
      student_roster_status: [
        ROSTER_STATUS.SELECTED,
        ROSTER_STATUS.SUBMITTED,
        ROSTER_STATUS.ENROLLED,
      ],
    },
  },
  3: {
    page: "student-info-review",
    text: "Submitted and Enrolled Students",
    needsSchoolId: true,
    dataEditing: false,
    availableStatuses: {
      schoolStatus: [...availableSchoolStatuses],
      student_roster_status: [ROSTER_STATUS.SUBMITTED, ROSTER_STATUS.ENROLLED],
    },
  },
};
