export const getSchoolId = pathname => {
  const parts = pathname.split("/");
  const schoolsIndex = parts.indexOf("schools");
  return schoolsIndex !== -1 ? parts[schoolsIndex + 1] : null;
};

export const getCurrentSchool = (schools, id) => {
  if (!id) return null;
  return schools.find(school => school.uuid === id) || null;
};

export const getCurrentPage = pathname => {
  if (!pathname) return null;
  const parts = pathname.split("/");
  const schoolsIndex = parts.indexOf("schools");
  if (!schoolsIndex) return parts[0];
  return parts[schoolsIndex + 2];
};

export const getPageEditing = (pathname, navItems) => {
  const currentPage = getCurrentPage(pathname);
  const currentPageItem = Object.values(navItems).find(
    item => item.page === currentPage
  );
  return currentPageItem ? currentPageItem.dataEditing : null;
};

export const getRosterNavItems = (navItems, pathname, schools) => {
  const schoolId = getSchoolId(pathname);
  const currentSchool = getCurrentSchool(schools, schoolId);

  const getItemAvailability = (school, item) => {
    if (!school) return false;

    const { schoolStatus, student_roster_status } = item.availableStatuses;

    // we're using an empty array to stand in for "all"
    const isSchoolStatusAvailable =
      schoolStatus.length === 0 || schoolStatus.includes(school.status);
    const isRosterStatusAvailable =
      student_roster_status.length === 0 ||
      student_roster_status.includes(school.student_roster_status);

    return isSchoolStatusAvailable && isRosterStatusAvailable;
  };

  // eslint-disable-next-line no-unused-vars
  return Object.entries(navItems).map(([_, item]) => {
    const currentEntry = pathname.includes(item.page);

    return {
      ...item,
      active: currentEntry,
      available: getItemAvailability(currentSchool, item),
      location: item.needsSchoolId
        ? `schools/${schoolId}/${item.page}`
        : item.page,
    };
  });
};
