import { Button } from "components";
import LandingLayout from "layouts/LandingLayout";
import { useEffect, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";

export default function SIComplete() {
  const { schools } = useRouteLoaderData("auth");
  const navigate = useNavigate();

  const [school, setSchool] = useState(null);

  useEffect(() => {
    if (schools.length > 1) {
      navigate("/school-selection");
    } else {
      setSchool(schools[0]);
    }
  }, [schools]);

  return (
    <LandingLayout
      title="Student Information Complete"
      bodyText={"To view student details, please click on the button below."}
      callToAction={
        <Button
          className="w-56 py-3 px-8 font-medium"
          design="primary"
          onClick={() =>
            navigate(`/schools/${school.uuid}/student-info-review`)
          }>
          View Students
        </Button>
      }
    />
  );
}
