import {
  Link,
  useActionData,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { DataGrid } from "components";
import {
  missingDataStudents,
  missingDataForm,
} from "utils/studentDataProcessing";
import { useEffect, useState } from "react";

export default function StudentInfoCompletionView() {
  const errors = useActionData();

  const { students, studentColumns } = useRouteLoaderData("students");
  const { schoolId } = useParams();

  const selectedStudents = students.filter(student => student.selected);

  const [formData, setFormData] = useState({});
  const [isDataMissing, setIsDataMissing] = useState(
    missingDataStudents(selectedStudents)
  );

  // Set formData once page has loaded
  useEffect(() => {
    const form = document.querySelector("#student-info-completion-form");
    const formData = new FormData(form);
    const dataObject = Object.fromEntries(formData.entries());
    setFormData(dataObject);
  }, []);

  // Set formData when a value is entered
  const handleInputSetFormData = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  // useEffect that triggers whenever formData changes
  useEffect(() => {
    setIsDataMissing(missingDataForm(formData));
  }, [formData]);

  return (
    <div className="mt-16 mx-24">
      <h1 className="text-ignite-purple-dark text-2xl font-semibold">
        Complete Student Information
      </h1>
      <p className="mt-2 text-ignite-grey-dark leading-loose w-[770px]">
        Please ensure that all highlighted cells are updated. After making
        changes, click &quot;Save Progress&quot; to save your work. When all
        student information is complete, click &quot;Submit&quot;. If you need
        to remove students from the program, navigate to{" "}
        <Link
          className="text-ignite-purple underline"
          to={`/schools/${schoolId}/student-selection`}>
          Select Students
        </Link>{" "}
        and deselect them.
      </p>
      <div className="w-full my-12">
        <DataGrid
          columns={studentColumns}
          rows={selectedStudents}
          height="600px"
          width="100%"
          formId="student-info-completion-form"
          onEdit={handleInputSetFormData}
          errors={errors?.errorCells}
        />
        <input
          aria-hidden={true}
          form="student-info-completion-form"
          className="invisible absolute bottom-0"
          name="roster-status"
          value={isDataMissing ? "selected" : "submitted"}
          readOnly
        />
      </div>
      {selectedStudents.length === 0 && (
        <p className="text-center pb-8">
          All selected students have been submitted.
        </p>
      )}
      <div className="flex my-12 justify-end gap-7 pb-20">
        <p className="ml-40 text-ignite-grey-dark text-sm text-right w-[430px]">
          The information you enter may be overwritten if data becomes available
          from your Student Information System (SIS).
        </p>
        {selectedStudents.length > 0 ? (
          <DataGrid.ErrorSummary errors={errors?.errorMessages || []}>
            <DataGrid.ErrorSummary.Submit
              formId="student-info-completion-form"
              className="py-3 px-8 font-medium"
              design={isDataMissing ? "tertiary" : "primary"}>
              {isDataMissing ? "Save progress" : "Submit"}
            </DataGrid.ErrorSummary.Submit>
            <DataGrid.ErrorSummary.Content />
          </DataGrid.ErrorSummary>
        ) : (
          <Link
            className="ignite-button-primary py-3 px-8 font-medium"
            to={`/schools/${schoolId}/student-info-review`}>
            Next
          </Link>
        )}
      </div>
    </div>
  );
}
