import PropTypes from "prop-types";
import classnames from "classnames";

/**
 * Reusable component for anchor or a tags (links) with styling. NOTE if you are using react-router and need to navigate to another
 * route, use the react-router Link component and use this styling.
 *
 * @param {string} ariaLabel - accessible label for screen readers (necessary if no text is present)
 * @param {string} className - any additional classes to add to the base component, for ex margins
 * @param {string} download - filename to download if the href is to a file
 * @param {string} href - link to navigate to
 * @param {string} referrerpolicy - referrer policy for the link
 * @param {string} rel - relationship of the link to the current page
 * @param {string} target - where the link should open
 * @param {string} testID - test id for testing
 * @param {node} children - any children to render inside the anchor tag
 */
export default function Anchor({
  ariaLabel,
  className,
  download,
  href,
  referrerpolicy,
  rel,
  target,
  testID = "xLink",
  children,
}) {
  return (
    <a
      className={classnames(
        "text-ignite-purple-dark underline hover:no-underline focus:bg-ignite-purple-dark focus:text-white",
        className
      )}
      href={href}
      referrerPolicy={referrerpolicy}
      rel={rel}
      target={target}
      data-testid={testID}
      aria-label={ariaLabel}
      download={download}>
      {children}
    </a>
  );
}

Anchor.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  download: PropTypes.string,
  href: PropTypes.string,
  referrerpolicy: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  testID: PropTypes.string,
  children: PropTypes.node,
};
