import {
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { useMemo, useRef, useState } from "react";
import TooltipContext from "./context";
import { placement, top } from "constants/enums/placement";

const ARROW_PADDING_OFFSET = 5;
const CLOSE_DELAY_IN_MS = 120;
const DEFAULT_MAIN_AXIS_OFFSET = 6;
const DEFAULT_SHIFT_PADDING = 5;
const OPEN_DELAY_IN_MS = 40;

const TooltipProvider = ({
  children,
  closeDelay = CLOSE_DELAY_IN_MS,
  enabled = true,
  offset: offsetConfig = {},
  onOpenChange: controlledOnOpenChange,
  onAfterOpenChange = noop,
  open: controlledOpen,
  openDelay = OPEN_DELAY_IN_MS,
  placement = top,
}) => {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const mainAxisOffset =
    DEFAULT_MAIN_AXIS_OFFSET +
    arrowRef.current?.getBoundingClientRect()?.height / 2;
  const floating = useFloating({
    middleware: [
      offset({ mainAxis: mainAxisOffset, ...offsetConfig }),
      flip(),
      shift({ padding: DEFAULT_SHIFT_PADDING }),
      arrow({
        element: arrowRef,
        padding: DEFAULT_MAIN_AXIS_OFFSET + ARROW_PADDING_OFFSET,
      }),
    ],
    open: controlledOpen || isOpen,
    placement,
    onOpenChange: controlledOnOpenChange || setIsOpen,
    whileElementsMounted: autoUpdate,
  });
  const hover = useHover(floating.context, {
    delay: {
      close: closeDelay,
      open: openDelay,
    },
    enabled,
    handleClose: safePolygon(),
  });
  const focus = useFocus(floating.context);
  const role = useRole(floating.context, { role: "tooltip" });
  const interactions = useInteractions([focus, hover, role]);
  const value = useMemo(
    () => ({
      ...floating,
      ...interactions,
      arrowRef,
      onAfterOpenChange,
    }),
    [floating, interactions, onAfterOpenChange]
  );

  return (
    <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>
  );
};

TooltipProvider.propTypes = {
  children: PropTypes.node.isRequired,
  /** Amount of delay for the tooltip to close in _ms_ */
  closeDelay: PropTypes.number,
  /** Prevent trigger to show the tooltip */
  enabled: PropTypes.bool,
  offset: PropTypes.object,
  /** Callback triggered after tooltip finished opening */
  onAfterOpenChange: PropTypes.func,
  /** Handler when tooltip in controlled mode */
  onOpenChange: PropTypes.func,
  /** Whether tooltip is opened in controlled mode */
  open: PropTypes.bool,
  /** Amount of delay for the tooltip to open in _ms_ */
  openDelay: PropTypes.number,
  /** Where should the tooltip be placed relative to the trigger */
  placement: PropTypes.oneOf(placement),
};

export default TooltipProvider;
