import { useMergeRefs } from "@floating-ui/react";
import PropTypes from "prop-types";
import { cloneElement, forwardRef, useContext } from "react";
import TooltipContext from "./context";

const TooltipTrigger = forwardRef(({ children, ...props }, propRef) => {
  const context = useContext(TooltipContext);
  const ref = useMergeRefs([context.refs.setReference, propRef, children?.ref]);

  return cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
    })
  );
});

TooltipTrigger.displayName = "Tooltip.Trigger";
TooltipTrigger.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TooltipTrigger;
