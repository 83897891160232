import classNames from "classnames";
import { PropTypes } from "prop-types";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import SignOutIcon from "assets/icons/logout.svg";

/**
 * Component showing the logged in user's name and opening to a menu.
 *
 * @param {string} firstName - The first name of the logged in user.
 * @param {string} lastName - The last name of the logged in user.
 * @param {string} className - Additional classes to add to the button.
 */
export default function ProfileButton({ firstName, lastName, className }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);

  const nameBadge = `${firstName?.toUpperCase().charAt(0)}${lastName?.toUpperCase().charAt(0)}`;

  const handleMenuToggle = () => {
    if (menuOpen) {
      //if toggling off
      setMenuOpen(false);
      buttonRef.current && buttonRef.current.focus({ preventScroll: true });
    } else {
      //if toggling on
      setMenuOpen(true);
    }
  };

  return (
    <>
      <button
        aria-label="Open Profile Menu"
        className={classNames(
          "text-base h-12 w-12 rounded-full bg-ignite-purple text-white font-semibold border-2 border-ignite-purple focus-visible:bg-white focus-visible:text-ignite-purple hover:bg-white hover:text-ignite-purple",
          className
        )}
        onClick={handleMenuToggle}
        data-testid="profile-button"
        id="profile-button"
        ref={buttonRef}
        aria-haspopup="true"
        aria-expanded={menuOpen}
        aria-controls="profile-menu">
        {nameBadge}
      </button>
      <div
        className={classNames("fixed inset-0", !menuOpen && "hidden")}
        onClick={handleMenuToggle}
        data-testid="profile-menu-overlay"
        aria-hidden={true}
      />
      <ul
        id="profile-menu"
        data-testid="profile-menu"
        role="menu"
        aria-labelledby="profile-button"
        className={classNames(
          "w-60 bg-white border rounded-lg shadow-lg absolute right-5 top-20 mt-10",
          !menuOpen && "hidden"
        )}>
        <div className="flex items-center p-4">
          <div className="flex items-center justify-center text-base h-12 w-12 rounded-full bg-ignite-purple text-white font-semibold">
            {nameBadge}
          </div>
          <span className="font-bold px-3">{`${firstName} ${lastName}`}</span>
        </div>
        <li
          role="menuitem"
          className="border-t px-5 py-2">
          <Link
            to="/signout"
            className="group flex py-1 rounded-lg hover:bg-ignite-purple focus-visible:bg-ignite-purple hover:text-white focus-visible:text-white">
            <SignOutIcon className="mx-2 group-hover:*:fill-white group-focus-visible:*:fill-white" />
            <span className="px-5">Sign out</span>
          </Link>
        </li>
      </ul>
    </>
  );
}

ProfileButton.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  className: PropTypes.string,
};
