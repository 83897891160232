//enum for special education program accommodations that a student needs

export const SPECIAL_EDUCATION_PROGRAM_ACCOMMODATIONS = [
  { label: "Additional Check for Understandings (CFU)", value: "cfu" },
  { label: "Chunking Lessons", value: "chunking_lessons" },
  { label: "Extended Time", value: "extended_time" },
  { label: "Extra Reminders", value: "extra_reminders" },
  {
    label: "Multiple Choice Answer Format",
    value: "multiple_choice_answer_format",
  },
  { label: "Model Read for Passages", value: "model_read_for_passages" },
  {
    label: "Reduction of Tasks/Activities",
    value: "reduction_of_tasks_activities",
  },
  { label: "Task Reminders", value: "task_reminders" },
  {
    label: "Simplified/Chunked Directions",
    value: "simplified_chunked_directions",
  },
  { label: "Visual Schedule", value: "visual_schedule" },
  {
    label: "Enlarged Text: Zoom Screen",
    value: "enlarged_text_zoom_screen",
  },
  { label: "Visual Cue", value: "visual_cue" },
  { label: "Additional Adult Support", value: "additional_adult_support" },
  { label: "None Applicable to Ignite Reading", value: "none_applicable" },
];
