import { useState } from "react";
import {
  DataGrid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Legend,
  LegendItem,
} from "components";
import { useRouteLoaderData } from "react-router-dom";
import LegendIcon from "assets/icons/info.svg";
import { rightEnd } from "constants/enums/placement";
import { ENROLLMENT_STATUS } from "constants/enums/enrollment";

export default function StudentInfoReview() {
  const { students, studentColumns } = useRouteLoaderData("students");
  const [legendOpen, setLegendOpen] = useState(false);

  const submittedOrEnrolledStudents = students.filter(student =>
    [ENROLLMENT_STATUS.SUBMITTED, ENROLLMENT_STATUS.ENROLLED].includes(
      student.enrollment_status
    )
  );

  return (
    <div className="mt-16 mx-24">
      <h1 className="text-ignite-purple-dark text-2xl font-semibold">
        Submitted and Enrolled Students
        <Popover
          onOpenChange={setLegendOpen}
          withClick={false}
          withHover={true}
          open={legendOpen}
          placement={rightEnd}>
          <PopoverTrigger>
            <button
              aria-label="Open legend"
              data-testid="open-legend"
              className="group ml-2">
              <LegendIcon className="[&>*]:group-hover:fill-ignite-purple [&>*]:group-focus:fill-ignite-purple" />
            </button>
          </PopoverTrigger>
          <PopoverContent
            hideCloseButton
            hideArrow>
            <Legend
              title="Unlock Efficiency with Our Color Coding System!"
              closeLegend={() => setLegendOpen(false)}
              data-testid="legend">
              <LegendItem
                legendStyles="bg-white"
                title="White - Enrolled Students"
                description="This cell color indicates enrolled students."
              />
              <LegendItem
                legendStyles="bg-ignite-purple-highlight"
                title="Purple - Newly Submitted Students"
                description="This cell color indicates new student submissions."
              />
            </Legend>
          </PopoverContent>
        </Popover>
      </h1>
      <p className="mt-1 text-ignite-grey-dark w-[770px]">
        Student Information.
      </p>
      <div className="w-full my-5">
        <DataGrid
          columns={studentColumns}
          allowEdits={false}
          rows={submittedOrEnrolledStudents}
          height="600px"
          width="100%"
        />
      </div>
    </div>
  );
}
