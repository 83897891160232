import { Button } from "components";
import LandingLayout from "layouts/LandingLayout";
import { useEffect, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";

export default function SIIncomplete() {
  const { schools } = useRouteLoaderData("auth");
  const navigate = useNavigate();

  const [school, setSchool] = useState(null);

  useEffect(() => {
    if (schools.length > 1) {
      navigate("/school-selection");
    } else {
      setSchool(schools[0]);
    }
  }, [schools]);

  return (
    <LandingLayout
      title={
        <>
          Please Complete <br /> Student Information
        </>
      }
      bodyText={
        <>
          Please finalize the details for each selected student <br /> to
          complete the setup process.
        </>
      }
      callToAction={
        <Button
          className="py-3 px-8 font-medium"
          design="primary"
          onClick={() =>
            navigate(`/schools/${school.uuid}/student-info-completion`)
          }>
          Complete Student Information
        </Button>
      }
    />
  );
}
