//This is a temporary solution to needing to update the cell styling when the input has a set value
//This styling should be returned to DataGridRow when we implement SaveAsYouGo

import { useMergeRefs } from "@floating-ui/react";
import PropTypes from "prop-types";
import { cloneElement, forwardRef } from "react";
import twcx from "utils/twcx";

export default function WithWrapper({ children, shouldStartPink, value }) {
  return cloneElement(children, {
    className: twcx(
      children.props.className,
      "before:content-[''] before:absolute before:top-0 before:left-0 before:h-[101%] before:w-2.5 before:z-[2]",
      {
        "bg-ignite-pink-light before:bg-ignite-purple pl-2":
          shouldStartPink && !value, //needs update (pink styling)
      }
    ),
  });
}

WithWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  shouldStartPink: PropTypes.bool.isRequired,
  value: PropTypes.any,
};

export const WithWrapperRef = forwardRef(
  ({ children, shouldStartPink, value, ...props }, propRef) => {
    const ref = useMergeRefs([propRef, children?.ref]);

    return cloneElement(children, {
      ref,
      ...props,
      className: twcx(
        children.props.className,
        "before:content-[''] before:absolute before:top-0 before:left-0 before:h-[101%] before:w-2.5 before:z-[2]",
        {
          "bg-ignite-pink-light before:bg-ignite-purple pl-2":
            shouldStartPink && !value, //needs update (pink styling)
        }
      ),
    });
  }
);

WithWrapperRef.propTypes = {
  children: PropTypes.node.isRequired,
  shouldStartPink: PropTypes.bool.isRequired,
  value: PropTypes.any,
};
