import Checkbox from "components/Checkbox";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

const Options = ({ onChange, options, selectedValues, name, onBlur }) => {
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current
        .getElementsByTagName("input")[0]
        .focus({ preventScroll: true });
    }
  }, [contentRef.current]);

  return (
    <ul className="overflow-scroll grow">
      {options.map((option, i) => (
        <li
          className="flex items-center hover:bg-zinc-100/70 py-1"
          ref={i === 0 ? contentRef : null}
          key={option.value}>
          <Checkbox
            checked={selectedValues.includes(option.value.toString())}
            label={option.label}
            value={option.value}
            name={name}
            onCheck={onChange}
            onBlur={onBlur}
            ariaLabel={option.label}
          />
        </li>
      ))}
    </ul>
  );
};

Options.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    })
  ).isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  onBlur: PropTypes.func,
};

export default Options;
