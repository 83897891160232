import SignOutRoute from "./SignOut";
import SignUpRoute from "./SignUp";
import ErrorBoundary from "views/ErrorBoundary";
import LinkNotActiveRoute from "./LinkNotActive";
import AuthRoute from "./AuthRoute";

export default {
  errorElement: <ErrorBoundary />,
  children: [SignOutRoute, SignUpRoute, LinkNotActiveRoute, AuthRoute],
};
