import classnames from "classnames";
import PropTypes from "prop-types";

const SelectedOptions = ({ options, selectedValues, name, error }) => {
  const selectedOptions = options.filter(option =>
    selectedValues.includes(option.value)
  );

  return (
    <>
      <ul className="text-left text-pretty">
        {selectedOptions.map(option => (
          <li
            className={classnames("pl-3 py-1 pr-10", {
              "text-error-red": error,
            })}
            key={option.value}>
            {option.label}
          </li>
        ))}
      </ul>
      <input
        aria-hidden={true}
        className="invisible absolute bottom-0"
        name={name}
        value={selectedOptions.map(option => option.value)}
        readOnly
      />
    </>
  );
};

SelectedOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    })
  ).isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  name: PropTypes.string,
  error: PropTypes.bool,
};

export default SelectedOptions;
