import { fullGradeDict } from "constants/dictionaries/grade";
import { SORT_DIRECTION, SORT_TYPE } from "constants/enums/sort";

const sortAlphanumerically = (rows, sortColumn, sortDirection) => {
  return rows.sort((a, b) => {
    const aValue = a.cells[sortColumn].value;
    const bValue = b.cells[sortColumn].value;

    //handle null values
    if (!aValue && !bValue) return 0;
    if (!aValue) return sortDirection === SORT_DIRECTION.ASCENDING ? 1 : -1;
    if (!bValue) return sortDirection === SORT_DIRECTION.ASCENDING ? -1 : 1;

    const compareResult = aValue.localeCompare(bValue, "en", { numeric: true });
    return sortDirection === SORT_DIRECTION.ASCENDING
      ? compareResult
      : -compareResult;
  });
};

const sortByDate = (rows, sortColumn, sortDirection) => {
  return rows.sort((a, b) => {
    const aValue = new Date(a.cells[sortColumn].value);
    const bValue = new Date(b.cells[sortColumn].value);

    //handle null values
    if (!aValue && !bValue) return 0;
    if (!aValue) return sortDirection === SORT_DIRECTION.ASCENDING ? 1 : -1;
    if (!bValue) return sortDirection === SORT_DIRECTION.ASCENDING ? -1 : 1;

    return sortDirection === SORT_DIRECTION.ASCENDING
      ? aValue - bValue
      : bValue - aValue;
  });
};

const sortByGrade = (rows, sortColumn, sortDirection) => {
  return rows.sort((a, b) => {
    const aMapping = fullGradeDict[a.cells[sortColumn].value];
    const bMapping = fullGradeDict[b.cells[sortColumn].value];
    const compareResult = aMapping - bMapping;
    return sortDirection === SORT_DIRECTION.ASCENDING
      ? compareResult
      : -compareResult;
  });
};

export const sortRows = (rows, sortColumn, sortType, sortDirection) => {
  if (sortDirection === "none") return;
  let sortedRows;

  switch (sortType) {
    case SORT_TYPE.ALPHANUMERIC:
      sortedRows = sortAlphanumerically(rows, sortColumn, sortDirection);
      break;
    case SORT_TYPE.DATE:
      sortedRows = sortByDate(rows, sortColumn, sortDirection);
      break;
    case SORT_TYPE.GRADE:
      sortedRows = sortByGrade(rows, sortColumn, sortDirection);
      break;
    case SORT_TYPE.DISABLED:
      sortedRows = rows;
      break;
    default:
      break;
  }
  return sortedRows;
};

export const updateActiveSort = (sortColumn, columns, sortDirection) => {
  if (columns[sortColumn].sortType === SORT_TYPE.DISABLED) return columns;
  columns.forEach((column, index) => {
    column.activeSortDirection =
      index === sortColumn ? sortDirection : SORT_DIRECTION.NONE;
  });
  return columns;
};
