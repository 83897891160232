import PropTypes from "prop-types";
import Logo from "assets/logos/logo.svg";
import ProfileButton from "components/ProfileButton";
import { useLoaderData, useLocation } from "react-router-dom";
import { getCurrentSchool, getSchoolId } from "utils/nav";

/**
 * Reusable component for Layouts that require a header with a logo and profile button
 *
 * @param {string} firstName - users first name to display in the profile button
 * @param {string} lastName - users last name to display in the profile button
 */
export default function AuthHeader({ firstName, lastName }) {
  const { schools } = useLoaderData();
  const schoolId = getSchoolId(useLocation().pathname, schools);
  const currSchool = getCurrentSchool(schools, schoolId);

  return (
    <header>
      <div className="mx-5 mt-10 mb-5 flex justify-between content-center">
        <Logo
          data-testid="header-logo"
          className="w-32 m-5"
        />
        <div>
          {currSchool && (
            <span className="text-ignite-purple">{currSchool.name}</span>
          )}
          <ProfileButton
            firstName={firstName}
            lastName={lastName}
            className="m-5"
          />
        </div>
      </div>
    </header>
  );
}

AuthHeader.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};
