import School from "./class";
import Api from "../Api/class";

// eslint-disable-next-line react-refresh/only-export-components
const ApiService = new Api({
  baseUrl: import.meta.env.VITE_SPARK_API_URL,
  namespace: "school_service",
});

export default new School(ApiService);
