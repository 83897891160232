export default function NoStudentRecordsView() {
  return (
    <>
      <div className="content-center mx-[5%] xl:mx-40 navbar-height-offset text-center">
        <h1
          className="text-ignite-purple-dark text-[24px] mb-4"
          data-testid="no-student-records-imported-message">
          No Student Records Imported
        </h1>
        <h2 className="text-ignite-purple-dark text-[40px] font-bold leading-[48px] mb-7">
          Student Import Requires <br /> Additional Permissions
        </h2>
        <p className="text-ignite-grey-dark mt-3 text-[16px]">
          We couldn&apos;t import your student roster. Please adjust the <br />{" "}
          permissions in your Student Information System (SIS) to allow <br />{" "}
          Ignite Reading to access student records.
        </p>
      </div>
    </>
  );
}
