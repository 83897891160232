import AuthService from "services/Auth";
import SchoolService from "services/School";

export default {
  path: "/signout",
  loader: async () => {
    await SchoolService.signout();
    return await AuthService.signout();
  },
};
