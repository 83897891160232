import PropTypes from "prop-types";
import sadStarImage from "assets/images/sad-star.png";

/**
 * Displays a generic error page with a status code and an optional message.
 * Used to indicate an error status encountered in the application.
 *
 * Props:
 * - status (string | number): The error status code to display.
 * - message (string): An optional message providing additional context about the error.
 */

const ErrorMessage = ({ status, message }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center">
      <img
        src={sadStarImage}
        alt="Sad Star"
        className="w-32 max-w-xs h-auto mb-1"
      />
      <div className="max-w-md space-y-4">
        <h1
          className="text-ignite-purple-dark text-[36px]"
          data-testid="error-status">
          {status}
        </h1>
        <h2
          className="text-ignite-grey-dark text-[13px] font-bold"
          data-testid="error-message">
          {message}
        </h2>
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.string,
};

export default ErrorMessage;
