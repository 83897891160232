export const fullGradeDict = {
  kindergarten: 0,
  first_grade: 1,
  second_grade: 2,
  third_grade: 3,
  fourth_grade: 4,
  fifth_grade: 5,
  sixth_grade: 6,
  seventh_grade: 7,
  eighth_grade: 8,
  ninth_grade: 9,
  tenth_grade: 10,
  eleventh_grade: 11,
  twelfth_grade: 12,
};
