import { studentColumns } from "constants/metadata/studentColumns";

export const validateEmail = email => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validatePassword = password => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
  return regex.test(password);
};

export const validateSEPAccommodations = (key, formObject) => {
  const sepKey = key.replace(
    /special-education-program-accommodations/,
    "special-education-program"
  );

  const sepValue = formObject?.[sepKey];
  const sepNoneLabel = studentColumns.find(
    column => column.key === "special-education-program"
  ).noneLabel;

  return sepValue !== sepNoneLabel;
};

export const validateSEP = (key, formObject) => {
  const sepValue = formObject?.[key];
  const sepNoneLabel = studentColumns.find(
    column => column.key === "special-education-program"
  ).noneLabel;

  const presentSepValue = sepValue !== sepNoneLabel;

  if (!presentSepValue) return true;

  const sepAccommodationsKey = key.replace(
    /special-education-program/,
    "special-education-program-accommodations"
  );
  const sepAccommodationsValue = formObject?.[sepAccommodationsKey];

  return sepAccommodationsValue !== "[]";
};

export const validateSchoolSeat = (aggCounters, cellValue, schoolSeat) => {
  aggCounters[cellValue] = (aggCounters[cellValue] || 0) + 1;
  return aggCounters[cellValue] <= schoolSeat.num_seats;
};
