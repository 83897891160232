export const SORT_DIRECTION = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
  NONE: "none",
};

export const SORT_TYPE = {
  ALPHANUMERIC: "alphanumeric",
  DATE: "date",
  GRADE: "grade",
  DISABLED: "disabled",
};
