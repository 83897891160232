//enum for language spoken at home

export const LANGUAGE_SPOKEN_AT_HOME = [
  { label: "Arabic", value: "arabic" },
  { label: "English", value: "english" },
  { label: "French", value: "french" },
  { label: "Hindi", value: "hindi" },
  { label: "Portuguese", value: "portuguese" },
  { label: "Russian", value: "russian" },
  { label: "Spanish", value: "spanish" },
  { label: "Other", value: "other" },
];
