import {
  topStart,
  topEnd,
  right,
  rightStart,
  rightEnd,
  bottom,
  bottomStart,
  bottomEnd,
  left,
  leftStart,
  leftEnd,
} from "../enums/placement";

export const placementToOriginXMap = {
  [top]: 0.5,
  [topStart]: 0,
  [topEnd]: 1,
  [right]: 0,
  [rightStart]: 0,
  [rightEnd]: 0,
  [bottom]: 0.5,
  [bottomStart]: 0,
  [bottomEnd]: 1,
  [left]: 1,
  [leftStart]: 1,
  [leftEnd]: 1,
};
export const placementToOriginYMap = {
  [top]: 1,
  [topStart]: 1,
  [topEnd]: 1,
  [right]: 0.5,
  [rightStart]: 0,
  [rightEnd]: 1,
  [bottom]: 0,
  [bottomStart]: 0,
  [bottomEnd]: 1,
  [left]: 0.5,
  [leftStart]: 0,
  [leftEnd]: 1,
};
export const placementToExitXMap = {
  [top]: 0,
  [topStart]: 0,
  [topEnd]: 0,
  [right]: -10,
  [rightStart]: -10,
  [rightEnd]: -10,
  [bottom]: 0,
  [bottomStart]: 0,
  [bottomEnd]: 0,
  [left]: 10,
  [leftStart]: 10,
  [leftEnd]: 10,
};
export const placementToExitYMap = {
  [top]: 10,
  [topStart]: 10,
  [topEnd]: 10,
  [right]: 0,
  [rightStart]: 0,
  [rightEnd]: 0,
  [bottom]: -10,
  [bottomStart]: -10,
  [bottomEnd]: -10,
  [left]: 0,
  [leftStart]: 0,
  [leftEnd]: 0,
};
