import { Outlet, useRouteLoaderData } from "react-router-dom";
import { HeaderNavigation } from "components";
import { useLocation } from "react-router-dom";
import { ROSTER_NAV_ITEMS } from "constants/dictionaries/nav";
import { getRosterNavItems } from "utils/nav";

/**
 * Additional layout for pages that are part of the rostering flow --
 * header includes navigation through the flow
 **/
export default function RosterLayout() {
  const { schools } = useRouteLoaderData("auth");
  let location = useLocation();

  const multipleSchools = schools.length > 1;
  const multipleSchoolsNavItems = getRosterNavItems(
    ROSTER_NAV_ITEMS,
    location.pathname,
    schools
  );
  const singleSchoolNavItems = multipleSchoolsNavItems.slice(1);

  return (
    <>
      <HeaderNavigation
        navItems={
          multipleSchools ? multipleSchoolsNavItems : singleSchoolNavItems
        }
      />
      <Outlet />
    </>
  );
}
