import {
  SCHOOL_STATUS,
  SCHOOL_SYNC_STATUS,
  ROSTER_STATUS,
} from "constants/enums/school_status";

/**
 * There are many possible school statuses based on three factors:
 *   SIS - If the SIS info has been imported
 *   SH - If the student headcount has been filled
 *   SI - If all student information is complete
 *
 * This translates to the following statuses:
 *   1. unimported - SIS: false, SH: false, SI: false
 *    SCHOOL_SYNC_STATUS.FAILED
 *   2. imported-records - SIS: true, SH: false, SI: false (records returned)
 *    SCHOOL_SYNC_STATUS.SUCCESS
 *    ROSTER_STATUS.NOT_STARTED
 *   3. imported-no-records - SIS: true, SH: false, SI: false (no records returned)
 *    SCHOOL_SYNC_STATUS.NO_RECORDS
 *   4. si-incomplete- SIS: true, SH: true, SI: false
 *    SCHOOL_SYNC_STATUS.SUCCESS
 *    ROSTER_STATUS.SELECTED
 *   5. sh-incomplete - SIS: true, SH: false, SI: true
 *    not possible since we require the user to select "submit" even if there is no missing data
 *   6. ready - SIS: true, SH: true, SI: true
 *    SCHOOL_SYNC_STATUS.SUCCESS
 *    ROSTER_STATUS.SELECTED
 *
 * Each status could potentially have its own UI, but currently multiple statuses share a UI,
 * so we can use a default.
 */

export const assignStatus = schools => {
  schools.forEach(school => {
    switch (school.sync_state) {
      case SCHOOL_SYNC_STATUS.FAILED:
        school.status = SCHOOL_STATUS.UNIMPORTED;
        break;
      case SCHOOL_SYNC_STATUS.SUCCESS:
        switch (school.student_roster_status) {
          case ROSTER_STATUS.NOT_STARTED:
            school.status = SCHOOL_STATUS.IMPORTED_RECORDS;
            break;
          case ROSTER_STATUS.SELECTED:
            if (school.num_open_seats > 0) {
              school.status = SCHOOL_STATUS.OPEN_SEATS;
            } else {
              school.status = SCHOOL_STATUS.SI_INCOMPLETE;
            }
            break;
          case ROSTER_STATUS.SUBMITTED:
          case ROSTER_STATUS.ENROLLED:
            if (school.num_open_seats > 0) {
              school.status = SCHOOL_STATUS.OPEN_SEATS;
            } else if (school.num_students_by_enrollment_status?.selected > 0) {
              school.status = SCHOOL_STATUS.SI_INCOMPLETE;
            } else {
              school.status = SCHOOL_STATUS.READY;
            }
            break;
          default:
            console.error("Unknown roster state");
            school.status = SCHOOL_STATUS.IMPORTED_RECORDS;
            break;
        }
        break;
      case SCHOOL_SYNC_STATUS.NO_RECORDS:
        school.status = SCHOOL_STATUS.IMPORTED_NO_RECORDS;
        break;
      default:
        console.error("Unknown sync state");
        school.status = SCHOOL_STATUS.UNIMPORTED;
        break;
    }
  });
};
