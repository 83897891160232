import classnames from "classnames";
import PropTypes from "prop-types";
import CheckboxIcon from "assets/icons/checkbox.svg";
import { useId } from "react";
/**
 * Reusable and styled checkbox component. Only use the aria-label if you are unable to
 * wrap the entire component in a native <label> element.
 *
 * @param {boolean} checked - boolean to indicate if checkbox is checked
 * @param {boolean} disabled - boolean to indicate if checkbox is disabled
 * @param {func} onCheck - function to run on checkbox check
 * @param {func} onBlur - function to run on blur event
 * @param {string} [ariaLabel] - (optional) accessible label for screen readers
 * @param {string} className - any additional classes to add to the base component
 * @param {string} name - name of the input which should follow the pattern of `${field-name}_${uuids}`
 * @param {string} value - value of the checkbox
 * @param {string} label - label for the checkbox
 * when used in a DataGrid
 */
const Checkbox = ({
  checked = false,
  disabled = false,
  label = "",
  value,
  onCheck,
  onBlur,
  ariaLabel,
  className,
  name,
  ...args
}) => {
  const id = useId();

  return (
    <span
      className={classnames(
        "relative inline-flex items-center justify-center mx-4",
        { "opacity-40": disabled, "cursor-pointer": !disabled },
        className
      )}>
      <input
        type="checkbox"
        checked={checked}
        name={name}
        value={value}
        id={`${id}-${value}`}
        data-testid={`checkbox-${value}`}
        onChange={onCheck}
        onBlur={onBlur}
        aria-label={ariaLabel}
        disabled={disabled}
        className="peer z-10 cursor-[inherit] absolute opacity-0 w-full h-full top-0 right-0"
        {...args}
      />
      <div
        className={classnames(
          "shrink-0 z-[1] w-6 h-6 border-[1px] bg-[#F2F2F2] border-ignite-grey-dark rounded-[5px] flex items-center justify-center",
          "peer-hover:outline-2 peer-hover:outline-offset-2 peer-hover:ring-1 peer-hover:ring-ignite-purple peer-focus:outline-2 peer-focus:outline-offset-2 peer-focus:ring-1 peer-focus:ring-ignite-purple"
        )}>
        {checked && <CheckboxIcon />}
      </div>
      {label && (
        <label
          className="cursor-pointer grow py-3 px-3 text-sm text-zinc-500"
          htmlFor={`${id}-${value}`}>
          {label}
        </label>
      )}
    </span>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onCheck: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
};

export default Checkbox;
