//enum for grade that a student belongs to

export const GRADE_LEVEL = [
  { label: "Kindergarten", value: "kindergarten" },
  { label: "1st", value: "first_grade" },
  { label: "2nd", value: "second_grade" },
  { label: "3rd", value: "third_grade" },
  { label: "4th", value: "fourth_grade" },
  { label: "5th", value: "fifth_grade" },
  { label: "6th", value: "sixth_grade" },
  { label: "7th", value: "seventh_grade" },
  { label: "8th", value: "eighth_grade" },
  { label: "9th", value: "ninth_grade" },
  { label: "10th", value: "tenth_grade" },
  { label: "11th", value: "eleventh_grade" },
  { label: "12th", value: "twelfth_grade" },
];
