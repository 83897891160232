import { studentDataDecoration } from "utils/studentDataProcessing";
import SchoolService from "services/School";
import AuthService from "services/Auth";
import { redirect } from "react-router-dom";
import StudentSelectionRoute from "./StudentSelection";
import StudentInfoCompletionRoute from "./StudentInfoCompletion";
import StudentsReviewRoute from "./StudentInfoReview";
import { studentColumns } from "constants/metadata/studentColumns";

export default {
  id: "students",
  path: "/schools/:schoolId",
  children: [
    StudentInfoCompletionRoute,
    StudentSelectionRoute,
    StudentsReviewRoute,
  ],
  loader: async ({ params }) => {
    const SPHINX_GATE_API_URL = import.meta.env.VITE_SPHINX_GATE_API_URL;
    //check for school id in path
    const { schoolId } = params;
    //redirect if schoolId is missing
    if (!schoolId) return redirect(`/`);
    await AuthService.auth();
    if (AuthService.isAuthenticated) {
      SchoolService.api.token = AuthService.api.token;

      const { schools } = await SchoolService.schools();
      const foundValidSchoolId = schools.find(
        school => school.uuid === schoolId
      );
      //redirect if schoolId is invalid
      if (!foundValidSchoolId) return redirect(`/`);

      const { students } = await SchoolService.students(schoolId);

      await studentDataDecoration(students.students);

      const school = schools.filter(row => row.uuid === schoolId)[0];
      studentColumns.filter(
        column => column.key == "school-seat-id"
      )[0].options = school.school_seats.map(seat => {
        return {
          label: seat.formatted_time_range,
          value: seat.id,
        };
      });

      return {
        numOpenSeats: school.num_open_seats,
        school: school,
        students: students.students,
        studentColumns,
      };
    }
    return redirect(`${SPHINX_GATE_API_URL}/login`);
  },
};
