export const cleanUrl = url => {
  return url.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
};

export const parseErrorMessage = error => {
  if (error?.response?.data["Message"]) {
    error = error.response.data["Message"];
  } else if (error?.Message) {
    error = error.Message;
  } else if (error?.message) {
    error = error.message;
  } else if (typeof error === "object") {
    error = "An unknown error occurred";
  }

  return error;
};
