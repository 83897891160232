import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
} from "@floating-ui/react";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef, useContext } from "react";
import ErrorSummaryContext from "./context";
import twcx from "utils/twcx";
import {
  placementToExitXMap,
  placementToExitYMap,
  placementToOriginXMap,
  placementToOriginYMap,
} from "constants/dictionaries/placement";
import ErrorItem from "./ErrorItem";
import Button from "components/Button";
import WhiteCloseIcon from "assets/icons/close-white.svg";

const ANIMATE_IN_DURATION_IN_MS = 60;
const ANIMATE_OUT_DURATION_IN_MS = 80;

const ErrorSummaryContent = forwardRef(
  ({ className = "", ...props }, propRef) => {
    const {
      arrowRef,
      context: floatingContext,
      placement,
      errors,
      handleTrigger,
      ...context
    } = useContext(ErrorSummaryContext);
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const shouldReduceMotion = useReducedMotion();

    return (
      <AnimatePresence>
        {floatingContext.open ? (
          <FloatingPortal>
            <FloatingFocusManager
              context={floatingContext}
              modal={false}>
              <div
                ref={ref}
                className="z-[99]"
                style={{
                  ...context.floatingStyles,
                  ...props.style,
                }}
                {...context.getFloatingProps(props)}>
                <motion.div
                  className={twcx(
                    "max-w-[390px] rounded text-sm ring-1 ring-ignite-grey-light-alt bg-white shadow-xl ",
                    className
                  )}
                  initial={{ opacity: 0, scale: 1 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: { duration: ANIMATE_IN_DURATION_IN_MS / 1000 },
                    y: 0,
                  }}
                  exit={{
                    opacity: 0,
                    scale: shouldReduceMotion ? 1 : 0.8,
                    transition: { duration: ANIMATE_OUT_DURATION_IN_MS / 1000 },
                    x: shouldReduceMotion ? 0 : placementToExitXMap[placement],
                    y: shouldReduceMotion ? 0 : placementToExitYMap[placement],
                  }}
                  style={{
                    originX: placementToOriginXMap[placement],
                    originY: placementToOriginYMap[placement],
                  }}>
                  <div>
                    <div className="flex pl-5 pr-2 py-3 bg-ignite-purple h-10 w-full text-white font-semibold items-center justify-between">
                      We&apos;re unable to save the data with errors
                      <Button
                        design="icon"
                        onClick={handleTrigger}
                        testID="error-summary-close">
                        <WhiteCloseIcon />
                      </Button>
                    </div>
                    <div className="flex flex-col gap-2 max-h-[235px] overflow-y-auto scrollbar-thin scrollbar-thumb-ignite-purple scrollbar-track-transparent px-4 py-6">
                      {errors.map((error, i) => (
                        <ErrorItem
                          key={i}
                          error={error}
                        />
                      ))}
                    </div>
                  </div>
                  <FloatingArrow
                    className="stroke-ignite-grey-light-alt"
                    context={floatingContext}
                    fill={placement.includes("bottom") ? "#502C7D" : "white"}
                    ref={arrowRef}
                    strokeWidth={1}
                  />
                </motion.div>
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        ) : null}
      </AnimatePresence>
    );
  }
);

ErrorSummaryContent.displayName = "ErrorSummary.Content";
ErrorSummaryContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ErrorSummaryContent;
