import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
} from "@floating-ui/react";
import PropTypes from "prop-types";
import { useMemo, useRef, useState } from "react";
import ErrorSummaryContext from "./context";
import { placement, bottomEnd } from "constants/enums/placement";

const ARROW_PADDING_OFFSET = 5;
const DEFAULT_MAIN_AXIS_OFFSET = 6;
const DEFAULT_SHIFT_PADDING = 5;

const ErrorSummaryProvider = ({
  children,
  offset: offsetConfig = {},
  onSubmit,
  placement = bottomEnd,
  errors = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const mainAxisOffset =
    DEFAULT_MAIN_AXIS_OFFSET +
    arrowRef.current?.getBoundingClientRect()?.height / 2;

  const handleTrigger = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      if (onSubmit) onSubmit();
      setIsOpen(true);
    }
  };

  const floating = useFloating({
    middleware: [
      offset({ mainAxis: mainAxisOffset, ...offsetConfig }),
      flip(),
      shift({ padding: DEFAULT_SHIFT_PADDING }),
      arrow({
        element: arrowRef,
        padding: DEFAULT_MAIN_AXIS_OFFSET + ARROW_PADDING_OFFSET,
      }),
    ],
    open: errors.length > 0 && isOpen,
    placement,
    onOpenChange: handleTrigger,
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(floating.context);
  const dismiss = useDismiss(floating.context);
  const role = useRole(floating.context, { role: "tooltip" });
  const interactions = useInteractions([click, dismiss, role]);

  const value = useMemo(
    () => ({
      ...floating,
      ...interactions,
      handleTrigger,
      errors,
      arrowRef,
    }),
    [floating, interactions, errors]
  );

  return (
    <ErrorSummaryContext.Provider value={value}>
      {children}
    </ErrorSummaryContext.Provider>
  );
};

ErrorSummaryProvider.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.object,
  /** Function to call when the submit button is clicked */
  onSubmit: PropTypes.func,
  /** Where should the ErrorSummary should be placed relative to the trigger */
  placement: PropTypes.oneOf(placement),
  /** Errors to display in the ErrorSummary */
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      /** The error message */
      message: PropTypes.string.isRequired,
      /** The id of the input producing the input producing the error */
      cellId: PropTypes.string,
    })
  ),
};

export default ErrorSummaryProvider;
