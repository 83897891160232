export default function SisNotImportedView() {
  return (
    <>
      <div className="px-5 content-center text-center justify-items-center navbar-height-offset">
        <div>
          <h1
            className="text-ignite-purple-dark text-[24px] mb-4"
            data-testid="sis-not-imported-message">
            Your SIS is not yet imported.
          </h1>
          <h2 className="text-ignite-purple-dark text-[40px] font-bold max-w-3xl leading-[48px]">
            Reach out to your Customer Success Manager to begin the process.
          </h2>
        </div>
      </div>
    </>
  );
}
