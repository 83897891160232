import { SORT_DIRECTION } from "constants/enums/sort";
import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useState } from "react";
import {
  colsByKeys,
  rowsByKeys,
  rowsByName,
  updateStudentRows,
} from "utils/dataGrid";
import { sortRows, updateActiveSort } from "utils/sort";
import { dataGridColumns, dataGridRows } from "./proptypes";
import { applyErrors } from "utils/studentDataProcessing";

export const DataGridContext = createContext();

export const DataGridProvider = ({
  children,
  columns,
  rows,
  keys = [],
  onSelect = () => {},
  multiSelect = false,
  disableSelect = false,
  allowEdits = true,
  errors,
}) => {
  const [currRows, updateCurrRows] = useState([...rows]);
  const [currColumns, updateCurrColumns] = useState([...columns]);
  const [search, setSearch] = useState("");

  const onEditSaveProgress = event => {
    const updatedRows = updateStudentRows(event, currRows);
    updateCurrRows(updatedRows);
  };

  useEffect(() => {
    let initialRows = [
      ...currRows,
      ...rows.filter(
        row => row.uuid && !currRows.find(r => r.uuid === row.uuid)
      ),
    ];
    let initialColumns = [...currColumns];

    //filter by keys
    if (keys.length > 0) {
      initialColumns = colsByKeys(initialColumns, keys);
      initialRows = rowsByKeys(initialRows, keys);
    }

    initialRows = rowsByName(initialRows, search);

    if (errors) {
      initialRows = applyErrors(initialRows, errors);
    }

    updateCurrColumns(initialColumns);
    updateCurrRows(initialRows);
  }, [columns, keys, rows, search]);

  const toggleSort = (index, currSortDirection, sortType) => {
    const nextSortDirection =
      currSortDirection === SORT_DIRECTION.NONE ||
      currSortDirection === SORT_DIRECTION.DESCENDING
        ? SORT_DIRECTION.ASCENDING
        : SORT_DIRECTION.DESCENDING;

    sortRows(currRows, index, sortType, nextSortDirection);
    updateActiveSort(index, currColumns, nextSortDirection);

    updateCurrColumns([...currColumns]);
    updateCurrRows([...currRows]);
  };

  const value = useMemo(
    () => ({
      currRows,
      currColumns,
      onSelect,
      onSearch: setSearch,
      search,
      toggleSort,
      multiSelect,
      disableSelect,
      allowEdits,
      onEditSaveProgress,
      errors,
    }),
    [currRows, currColumns, disableSelect, search]
  );

  return (
    <DataGridContext.Provider value={value}>
      {children}
    </DataGridContext.Provider>
  );
};

DataGridProvider.propTypes = {
  children: PropTypes.node.isRequired,
  columns: dataGridColumns,
  rows: dataGridRows,
  keys: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  multiSelect: PropTypes.bool,
  disableSelect: PropTypes.bool,
  allowEdits: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
};
