//enum for race that a student is

export const RACE = [
  {
    label: "American Indian or Alaska Native",
    value: "american_indian_or_alaska_native",
  },
  { label: "Asian", value: "asian" },
  { label: "Black or African American", value: "black_or_african_american" },
  {
    label: "Native Hawaiian or Other Pacific Islander",
    value: "native_hawaiian_or_other_pacific_islander",
  },
  { label: "White", value: "white" },
  { label: "Other", value: "other" },
  { label: "Unknown", value: "unknown" },
  { label: "Two or More Races", value: "two_or_more_races" },
];
