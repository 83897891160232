import Auth from "./class";
import Api from "../Api/class";

// eslint-disable-next-line react-refresh/only-export-components
const ApiService = new Api({
  baseUrl: import.meta.env.VITE_SPHINX_GATE_API_URL,
  namespace: `api/${import.meta.env.VITE_SPHINX_GATE_VERSION}`,
});

export default new Auth(ApiService);
