import { useRouteError } from "react-router-dom";
import ErrorMessage from "components/ErrorMessage";

export default function ErrorBoundary() {
  const error = useRouteError();

  return (
    <ErrorMessage
      status={error.status}
      message={error.message ? error.message : "An error occurred"}
    />
  );
}
