export const top = "top";
export const topStart = "top-start";
export const topEnd = "top-end";
export const right = "right";
export const rightStart = "right-start";
export const rightEnd = "right-end";
export const bottom = "bottom";
export const bottomStart = "bottom-start";
export const bottomEnd = "bottom-end";
export const left = "left";
export const leftStart = "left-start";
export const leftEnd = "left-end";
export const placement = [
  top,
  topStart,
  topEnd,
  right,
  rightStart,
  rightEnd,
  bottom,
  bottomStart,
  bottomEnd,
  left,
  leftStart,
  leftEnd,
];
