import PropTypes from "prop-types";
import classnames from "classnames";
import CheckmarkIcon from "assets/icons/checkmark.svg";

/**
 * Stylized step component for the progress of the flow of completing student information
 * @param className - any additional classes to add to the step, for ex margins
 * @param text - text to go inside of the step component
 * @param stepStatus - status of the step which the styling of the component depends on:
 *  completed: the step is in the past and data has been collected (has a checkmark icon)
 *  current: the step is the currently displayed step (highlighted in pink)
 *  outstanding: the step is in the future and data has not been collected (greyed out)
 */
export default function InformationStep({ className, text, stepStatus }) {
  let parentClasses, circleClasses, textClasses;
  switch (stepStatus) {
    case "current":
      parentClasses = "bg-ignite-pink rounded-3xl";
      circleClasses = "bg-ignite-pink border-4 border-white w-8 h-8";
      textClasses = "text-white";
      break;
    case "outstanding":
      circleClasses = "white border-4 border-ignite-grey w-8 h-8";
      textClasses = "text-ignite-grey";
      break;
    default:
      parentClasses = "white";
      textClasses = "text-ignite-purple";
  }

  return (
    <div
      className={classnames(
        "flex flex-row w-80 h-12 mr-4",
        parentClasses,
        className
      )}>
      <div className={classnames("rounded-full ml-3 mt-2", circleClasses)}>
        {stepStatus === "completed" ? (
          <CheckmarkIcon aria-label="Completed step" />
        ) : null}
      </div>
      <div className={classnames("ml-3 mt-3 text-base font-bold", textClasses)}>
        {text}
      </div>
    </div>
  );
}

InformationStep.propTypes = {
  className: PropTypes.string, // accept className for placement
  text: PropTypes.string.isRequired,
  stepStatus: PropTypes.oneOf(["completed", "current", "outstanding"]),
};
