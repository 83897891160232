import ErrorMessage from "components/ErrorMessage";

export default function LinkNotActiveView() {
  const linkInactiveTitle = "This link is no longer active";
  const linkInactiveMessage =
    "To access the school portal you will need to ask the person who originally invited you for a new link.";
  return (
    <ErrorMessage
      status={linkInactiveTitle}
      message={linkInactiveMessage}
    />
  );
}
