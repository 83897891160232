import PropTypes from "prop-types";
import { forwardRef } from "react";
import classnames from "classnames";
import ErrorIcon from "assets/icons/x-circle-mini.svg";

/**
 * Reusable component for text input fields, also usable for password fields.
 *
 * For passwords, use the additionalMessage prop to provide requirements to the user, and change type to 'password'.
 *
 * @param {string} className - any additional classes to add to the base component, for ex margins
 * @param {boolean} [disabled = false] - boolean to disable input field
 * @param {string} defaultValue - default value for the input field
 * @param {boolean} [error = false] - boolean to indicate error state
 * @param {string} errorMessage - message to display when error is true
 * @param {string} [additionalMessage] - additional message to display below input field (useful for password requirements or other instructions)
 * @param {string} label - labels or titles the input field
 * @param {string} name - name of the input field, used as a reference
 * @param {func} [onBlur] - function to run on blur event
 * @param {func} [onChange] - function to run on change event
 * @param {func} [onFocus] - function to run on focus event
 * @param {func} [onInput] - function to run on input event
 * @param {string} pattern - regex pattern for input validation
 * @param {string} placeholder - placeholder text for input field
 * @param {string} autoComplete - autocomplete value for input field
 * @param {boolean} [required = false] - boolean to indicate required field
 * @param {string} testID - test id for testing
 * @param {string} [type = 'text'] - type of input field (e.x. text, email, password)
 */
const Input = forwardRef(function (
  {
    className,
    disabled = false,
    defaultValue,
    error = false,
    errorMessage,
    additionalMessage,
    label,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    onInput = () => {},
    pattern,
    placeholder,
    autoComplete,
    required = false,
    testID = "xInput",
    type = "text",
  },
  ref
) {
  return (
    <fieldset
      className={classnames("w-full pb-6", className)}
      data-testid={testID}>
      <label
        htmlFor={name}
        className={classnames(
          "text-sm font-semibold pb-4 block",
          !error && "text-ignite-purple-dark",
          error && "text-error-red"
        )}
        data-testid={`${testID}-label`}>
        {label}
      </label>
      <input
        id={name}
        ref={ref}
        data-testid={`${testID}-input`}
        className={classnames(
          "w-full h-14 rounded-md px-3.5 placeholder:text-xl border-[1.5px] focus-visible:outline-ignite-purple",
          !error && "border-ignite-blue-dark",
          error && "border-error-red placeholder:text-error-red"
        )}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onInput={onInput}
        placeholder={placeholder || label}
        type={type}
        pattern={pattern}
        autoComplete={autoComplete}
        required={required}
        disabled={disabled}
        name={name}
        {...(error
          ? { "aria-describedby": `${testID}-error` }
          : additionalMessage && { "aria-describedby": `${testID}-message` })}
      />
      {additionalMessage && (
        <p
          id={`${testID}-message`}
          data-testid={`${testID}-message`}
          className="pt-4 text-ignite-purple-dark font-medium text-sm">
          {additionalMessage}
        </p>
      )}
      {error && (
        <p
          id={`${testID}-error`}
          data-testid={`${testID}-error`}
          className="flex mt-3 p-4 rounded-md bg-error-red-light text-error-red font-medium text-sm">
          <ErrorIcon className="w-5 mr-2 inline-block" />
          {errorMessage}
        </p>
      )}
    </fieldset>
  );
});
Input.displayName = "Input";

Input.propTypes = {
  className: PropTypes.string, // accept className for placement
  defaultValue: PropTypes.any,
  design: PropTypes.oneOf(["primary", "secondary", "link"]), // built-in styles
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  additionalMessage: PropTypes.any,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
  pattern: PropTypes.any,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  testID: PropTypes.string,
  type: PropTypes.oneOf([
    "date",
    "datetime",
    "email",
    "number",
    "password",
    "tel",
    "text",
  ]), // only allowable types, button to ignore form submission
};

export default Input;
