import Button from "components/Button";
import PropTypes from "prop-types";
import twcx from "utils/twcx";

export function Legend({ title, closeLegend, children, ...args }) {
  return (
    <div
      className="p-7 max-w-[390px] border-[1px] rounded-[6px] border-ignite-grey-border"
      {...args}>
      <h1 className="text-xl font-bold mb-7">{title}</h1>
      {children}
      <div className="w-full flex justify-center">
        <Button
          className="mt-5 py-2 px-12"
          onClick={closeLegend}>
          Got it
        </Button>
      </div>
    </div>
  );
}

Legend.propTypes = {
  title: PropTypes.string.isRequired,
  closeLegend: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export function LegendItem({ legendStyles, title, description }) {
  return (
    <div className="flex mb-7">
      <div
        className={twcx(
          "w-[32px] h-[34px] border-[1px] border-ignite-grey-border rounded-[5px] shrink-0 mr-4",
          legendStyles
        )}
      />
      <div>
        <h2 className="font-bold">{title}</h2>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  );
}

LegendItem.propTypes = {
  legendStyles: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
